import mapboxGeocodingService from "@mapbox/mapbox-sdk/services/geocoding";
import mapboxgl from "mapbox-gl";
import { type GeocodeFeatureCollection } from "./GeocodeFeature";

mapboxgl.accessToken = "pk.eyJ1Ijoid2FnbmVyZ3JhcGhpY3MiLCJhIjoiY2puMGRlc2Y5MTlmbzNrcW9sZzd0enJ4MCJ9.9UzDSDeYnTP2Qwzsi8Q9_g";
const geocodingClient = mapboxGeocodingService({ accessToken: mapboxgl.accessToken });

export async function setupMaps() {
	for (var mapContainer of <NodeListOf<HTMLElement>>document.querySelectorAll(".map-container .map")) {
		let zoom = parseInt(mapContainer.dataset.zoom || "12", 10);
		let address = mapContainer.dataset.address;

		if (address) {
			geocodingClient
				.forwardGeocode({
					query: address,
					autocomplete: false,
					countries: ["CH"],
					limit: 1
				})
				.send()
				.then((response: { body: GeocodeFeatureCollection }) => {
					if (response && response.body && response.body.features && response.body.features.length) {
						var feature = response.body.features[0];

						var map = new mapboxgl.Map({
							container: mapContainer,
							style: "mapbox://styles/mapbox/streets-v10",
							center: feature.center,
							zoom: zoom
						});
						new mapboxgl.Marker()
							.setLngLat(feature.center)
							.addTo(map);
					}
				})
				.catch((err: any) => {
					console.log(err);
				});
		}
	}
}
